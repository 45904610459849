import React from 'react';
import ProfileMenu from './ProfileMenu';
import { redirectTo, navigate } from '@reach/router';
import { Button, useTheme, makeStyles } from '@material-ui/core';
import Icon from '@mdi/react';
import { mdiGithubCircle } from '@mdi/js';
import { auth } from '../../../stores/firebaseInit';
import firebase from 'firebase';

const useStyles = makeStyles((theme) => ({
  github: {
    marginRight: theme.spacing(),
  },
  button: {
    marginLeft: theme.spacing(),
  },
}));

export const ProfileButton = () => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const handleLoginClick = async () => {
    try {
      await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await auth.signInWithPopup(new firebase.auth.GithubAuthProvider());

      navigate('/');
    } catch (error) {
      console.log(error);
    }
  };

  return auth.currentUser ? (
    <ProfileMenu
      handleLogoutClick={() => {
        auth.signOut().then(() => redirectTo('/'));
      }}
    />
  ) : (
    <Button
      onClick={handleLoginClick}
      color="primary"
      variant="outlined"
      className={classes.button}
    >
      <Icon
        size={1}
        path={mdiGithubCircle}
        className={classes.github}
        color={theme.palette.primary.main}
      />
      Login
    </Button>
  );
};
