import React from 'react';
import { Router } from '@reach/router';

import ProtectedRoute from './components/ProtectedRoute';

import ProjectArchitecture from './pages/project/Architecture';
// import ArchitectureEditor from './pages/ArchitectureEditor';
import Landing from './pages/Landing';
import SoftwareItem from './pages/SoftwareItem';
import ProjectSoftwareItems from './pages/project/SoftwareItems';
import ProjectSoftwareItem from './pages/project/SoftwareItem';
import Team from './pages/Team';
import TeamSetup from './pages/TeamSetup';
import TechnicalReport from './pages/TechnicalReport';
import ProjectTechnicalReports from './pages/project/TechnicalReports';
import ProjectTechnicalReport from './pages/project/TechnicalReport';
import ProjectArchitectureSetup from './pages/project/ArchitectureSetup';
import SoftwareItemSetup from './pages/SoftwareItemSetup';
import { InstallationProvider } from './stores/InstallationProvider';
import {
  MuiThemeProvider,
  createMuiTheme,
  CssBaseline,
} from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { UiProvider } from './stores/UiProvider';
import SnackbarProvider from './stores/SnackbarProvider';
import SoftwareItemPull from './pages/SoftwareItemPull';
import ProjectOverview from './pages/project/Overview';
import ProjectProcesses from './pages/project/Processes';
import { ProfileProvider } from './stores/profileProvider';
import AccountOverview from './pages/account/Overview';
import Projects from './pages/account/Projects';
import AccountSettings from './pages/account/Settings';
import ProjectSettings from './pages/project/Settings';
import CreateProject from './pages/CreateProject';
import { AccountProvider } from './stores/accountProvider';
import ArchitectureChangeRequest from './pages/project/ArchitectureChangeRequest';

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
}

// const store = ConsoleStore.create({});

export const theme = createMuiTheme({
  palette: {
    primary: blue,
  },
  typography: {
    // useNextVariants: true
  },
});

const NotFound = () => <div>Not Found</div>;

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />

      <ProfileProvider>
        <AccountProvider>
          <InstallationProvider>
            <UiProvider>
              <SnackbarProvider>
                <Router>
                  <ProtectedRoute component={Landing} path="/" />
                  <ProtectedRoute component={TeamSetup} path="/setup/team" />

                  {/* transitioned */}
                  <ProtectedRoute
                    component={AccountOverview}
                    path="/u/:namespace/overview"
                  />
                  <ProtectedRoute
                    component={Projects}
                    path="/u/:namespace/projects"
                  />
                  <ProtectedRoute
                    component={ProjectOverview}
                    path="/u/:namespace/projects/:project"
                  />
                  <ProtectedRoute
                    component={ProjectArchitecture}
                    path="/u/:namespace/projects/:project/architecture"
                  />
                  <ProtectedRoute
                    component={ArchitectureChangeRequest}
                    path="/u/:namespace/projects/:project/architecture/change-requests/:pullId"
                  />
                  <ProtectedRoute
                    component={ProjectProcesses}
                    path="/u/:namespace/projects/:project/processes"
                  />
                  <ProtectedRoute
                    component={ProjectSettings}
                    path="/u/:namespace/projects/:project/settings"
                  />
                  <ProtectedRoute
                    component={ProjectSoftwareItems}
                    path="/u/:namespace/projects/:project/software-items"
                  />
                  <ProtectedRoute
                    component={ProjectSoftwareItem}
                    path="/u/:namespace/projects/:project/software-items/:itemId"
                  />
                  <ProtectedRoute
                    component={ProjectTechnicalReports}
                    path="/u/:namespace/projects/:project/records"
                  />
                  <ProtectedRoute
                    component={ProjectTechnicalReport}
                    path="/u/:namespace/projects/:project/records/:recordId"
                  />
                  <ProtectedRoute
                    component={ProjectArchitectureSetup}
                    path="/u/:namespace/projects/:project/setup/architecture"
                  />
                  <ProtectedRoute
                    component={AccountSettings}
                    path="/u/:namespace/settings"
                  />
                  <ProtectedRoute
                    component={CreateProject}
                    path="/u/:namespace/create/project"
                  />

                  {/* Old views */}
                  <ProtectedRoute
                    component={Team}
                    path="/t/:installationId/settings"
                  />
                  <ProtectedRoute
                    component={TechnicalReport}
                    path="/t/:installationId/technical-files/:id"
                  />
                  <ProtectedRoute
                    component={SoftwareItem}
                    path="/t/:installationId/software-items/:softwareItemId"
                  />
                  <ProtectedRoute
                    component={SoftwareItemPull}
                    path="/t/:installationId/software-items/:softwareItemId/pulls/:pullId"
                  />

                  <ProtectedRoute
                    component={SoftwareItemSetup}
                    path="/t/:installationId/setup/software-item/:softwareItemId"
                  />
                  {
                    // @ts-ignore
                    <NotFound default />
                  }
                </Router>
              </SnackbarProvider>
            </UiProvider>
          </InstallationProvider>
        </AccountProvider>
      </ProfileProvider>
    </MuiThemeProvider>
  );
};

export default App;
