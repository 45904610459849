import React from 'react';
import { withStyles } from '@material-ui/core';
import Backend from './backend';

import './styles.css';
import { useEffect } from 'react';
import { useCallback } from 'react';

const styles = (theme) => ({
  layout: {
    marginBottom: theme.spacing(),
    marginTop: theme.spacing(),
    //padding: theme.spacing(),
    border: '1px solid lightgray',
  },
});

const Viewer = (props) => {
  const { classes } = props;
  const svg = React.createRef();

  const backend = new Backend({
    borderWidth: 1,
    padding: 8,
  });

  const resize = useCallback(() => {
    backend.update(props);
  }, [backend, props]);

  useEffect(() => {
    window.addEventListener('resize', resize);

    return () => window.removeEventListener('resize', resize);
  }, [resize]);

  useEffect(() => {
    backend.setHostElement(svg.current);
    backend.removeOutput();
    backend.update(props);
  }, [backend, props, svg]);

  return (
    <div className={classes.layout}>
      <svg ref={svg} className="architecture-viewer" height="350" />
    </div>
  );
};

export default withStyles(styles)(Viewer);
