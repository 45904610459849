import React from 'react';

import { auth } from '../../../stores/firebaseInit';

import { withStyles, Popover } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';

import Profile from './Profile';

const styles = (theme) => ({
  button: {
    padding: theme.spacing() / 2,
  },
  primary: {},
  icon: {},
  avatar: {
    // width: 32,
    // height: 32
  },
});

class ProfileMenu extends React.Component {
  state = {
    open: false,
    anchorEl: null,
  };

  handleClose = () => {
    this.setState({
      open: false,
      anchorEl: null,
    });
  };

  handleClick = (event) => {
    this.setState({
      open: true,
      anchorEl: event.currentTarget,
    });
  };

  render() {
    const { classes } = this.props;
    const user = auth.currentUser;

    return (
      <React.Fragment>
        <IconButton
          aria-label="curren user profile"
          aria-controls="menu-profile"
          aria-haspopup="true"
          onClick={this.handleClick}
          color="default"
          className={classes.button}
        >
          <Avatar
            className={classes.avatar}
            alt={user.displayName}
            src={user.photoURL}
          />
        </IconButton>
        <Popover
          id="menu-profile"
          anchorEl={this.state.anchorEl}
          open={this.state.open}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Profile />
        </Popover>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ProfileMenu);
