import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Brand from '../common/Brand';
import { Avatar, Typography, useTheme, makeStyles } from '@material-ui/core';

import { Link } from '@reach/router';
import { namespaceOverviewUrl } from '../../../utils/urls';
import { useUiContext } from '../../../stores/UiProvider';
import { useProfile } from '../../../stores/profileProvider';
import ErrorBoundary from '../../ErrorBoundary';

import { menuItems } from './menuItems';
import { useAccount } from '../../../stores/accountProvider';
import { DrawerMenu } from '../common/Drawer';
import { ProfileButton } from '../common/ProfileButton';
import { Helmet } from 'react-helmet';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    //width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

/**
 *
 * @param {object} props
 * @param {object} props.children
 * @param {string} [props.namespace]
 * @param {string} [props.title]
 */
export function Page({ children, namespace, title }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [{ openNavigationDrawer }] = useUiContext();

  const [profile] = useProfile();

  useAccount(namespace);

  const [teamInfo, setTeamInfo] = useState(null);

  useEffect(() => {
    profile &&
      setTeamInfo(profile.teams.find((team) => team.namespace === namespace));
  }, [namespace, profile]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`${namespace} - ${title} - CompliancePal console`}</title>
      </Helmet>
      <AppBar
        color="default"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openNavigationDrawer,
        })}
      >
        <Toolbar>
          <Brand></Brand>
          {teamInfo && (
            <Link
              style={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                marginRight: theme.spacing(1),
              }}
              to={namespaceOverviewUrl(namespace)}
            >
              <Typography
                variant="h5"
                color="textSecondary"
                style={{
                  marginLeft: theme.spacing(1),
                  marginRight: theme.spacing(1),
                  fontWeight: 'lighter',
                }}
              >
                /
              </Typography>
              <Avatar
                src={teamInfo.avatar}
                style={{
                  width: 30,
                  height: 30,
                  marginRight: theme.spacing(1),
                }}
              />
              <Typography variant="h6" color="textSecondary">
                {teamInfo.namespace}
              </Typography>
            </Link>
          )}
          <div
            style={{
              flexGrow: 1,
            }}
          />
          <ProfileButton />
        </Toolbar>
      </AppBar>

      {namespace && <DrawerMenu items={menuItems(namespace)} />}
      <main className={classes.content}>
        <div className={classes.toolbar}></div>
        <ErrorBoundary>{children}</ErrorBoundary>
      </main>
    </div>
  );
}

export default Page;
