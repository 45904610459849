import React from 'react';
import { Typography, Button, useTheme } from '@material-ui/core';

const ViewPullRequestStep = ({ href }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Typography>
        We have opened a pull request to configure your project. Review it to
        continue!
      </Typography>
      <div>
        <Button
          color="primary"
          disabled={!href}
          href={href}
          style={{
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
          }}
          target="_blank"
          variant="contained"
        >
          View Pull request
        </Button>
      </div>
    </React.Fragment>
  );
};

export default ViewPullRequestStep;
