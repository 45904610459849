import React, { Fragment } from 'react';
import ProjectPage from '../../components/pages/Project';
import PageHeader from '../../components/PageHeader';
import {
  Container,
  Card,
  CardHeader,
  CardActions,
  Button,
} from '@material-ui/core';

const ProjectSettings = () => {
  return (
    <Fragment>
      <PageHeader title="Project settings" tabs={['General']} />
      <Container maxWidth="md">
        <Card>
          <CardHeader title="Delete your project" />
          <CardActions>
            <Button size="small" color="secondary" disabled>
              Delete
            </Button>
          </CardActions>
        </Card>
      </Container>
    </Fragment>
  );
};

export default (props) => (
  <ProjectPage title="Settings" {...props}>
    <ProjectSettings {...props} />
  </ProjectPage>
);
