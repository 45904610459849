import React from 'react';
import Page from '../components/pages/Account';
import {
  Container,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  Button,
} from '@material-ui/core';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { useProfile } from '../stores/profileProvider';
import { navigate } from '@reach/router';
import { namespaceProjectUrl } from '../utils/urls';
import { callables } from '../utils/callables';

const CreateProject = ({ namespace }) => {
  const [profile] = useProfile();

  const team = profile.teams.find((team) => team.namespace === namespace);

  return (
    <Page namespace={namespace}>
      <Container>
        <Card
          style={{
            width: '500px',
          }}
        >
          <Formik
            initialValues={{
              name: '',
              title: '',
              description: '',
            }}
            validate={(values) => {
              const errors = {};

              Object.keys(values).forEach((key) => {
                if (!values[key]) {
                  errors[key] = 'Required';
                }
              });

              return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
              await callables('projects-newProject')({
                ...values,
                accountId: team.id,
              });

              setSubmitting(false);

              navigate(namespaceProjectUrl(namespace, values.name));
            }}
          >
            {({ submitForm, isSubmitting }) => {
              return (
                <Form>
                  <CardHeader title="Create Project" />
                  <CardContent>
                    <Field
                      component={TextField}
                      type="text"
                      name="name"
                      label="Name"
                      required
                      fullWidth
                    />
                    <Field
                      component={TextField}
                      type="text"
                      name="title"
                      label="Title"
                      required
                      fullWidth
                    />
                    <Field
                      required
                      component={TextField}
                      multiline
                      rows={6}
                      type="text"
                      name="description"
                      label="Project description"
                      fullWidth
                    ></Field>
                  </CardContent>
                  <CardActions>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      onClick={submitForm}
                    >
                      Create
                    </Button>
                  </CardActions>
                </Form>
              );
            }}
          </Formik>
        </Card>
      </Container>
    </Page>
  );
};

export default CreateProject;
