import { mdiHome, mdiFileCabinet, mdiAccount } from '@mdi/js';
import {
  namespaceOverviewUrl,
  namespaceSettingsUrl,
  namespaceProjectsUrl,
} from '../../../utils/urls';
export const menuItems = (namespace) => [
  {
    href: namespaceOverviewUrl(namespace),
    path: mdiHome,
    text: 'Overview',
  },
  {
    href: namespaceProjectsUrl(namespace),
    path: mdiFileCabinet,
    text: 'Projects',
  },
  {
    href: namespaceSettingsUrl(namespace),
    path: mdiAccount,
    text: 'Settings',
  },
];
