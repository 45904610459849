import React, { useState, useEffect } from 'react';
import Page from '../../components/pages/Project';
import { firestore } from '../../stores/firebaseInit';
import {
  Container,
  Card,
  CardContent,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core';
import PageHeader from '../../components/PageHeader';
import { collections } from '../../utils/db';
import { useAccount } from '../../stores/accountProvider';
import ReactTimeago from 'react-timeago';
import Status from '../../components/Status';
import { Link } from '@reach/router';
import { projectSoftwareItemUrl } from '../../utils/urls';

const TechnicalReports = ({ namespace, project }) => {
  const [adrs, setAdrs] = useState([]);
  const [, setLoading] = useState(true);

  const [account] = useAccount(namespace);

  useEffect(() => {
    return account && account.id && project
      ? firestore
          .collection(collections.ACCOUNTS)
          .doc(account.id)
          .collection(collections.PROJECTS)
          .doc(project)
          .collection(collections.DECISIONS)
          .onSnapshot((snap) => {
            setLoading(false);
            setAdrs(snap.docs.map((doc) => doc.data()));
          })
      : () => {};
  }, [account, project]);

  return (
    <Page namespace={namespace} project={project} title="Records">
      <PageHeader title="Records" tabs={['Catalog']}></PageHeader>
      <Container>
        <Card>
          {/* <CardHeader title="Change decisions"></CardHeader> */}
          <CardContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="none">Id</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Item</TableCell>
                  <TableCell>Parent</TableCell>
                  <TableCell>Created</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {adrs.map((adr) => {
                  return (
                    <TableRow key={adr.decisionId}>
                      <TableCell padding="none">
                        <Link to={adr.decisionId}>{adr.decisionId}</Link>
                      </TableCell>
                      <TableCell>title</TableCell>
                      <TableCell>
                        <Link
                          to={projectSoftwareItemUrl(
                            namespace,
                            project,
                            adr.assetId
                          )}
                        >
                          {adr.assetId}
                        </Link>
                      </TableCell>
                      <TableCell>{adr.parent}</TableCell>
                      <TableCell>
                        <ReactTimeago
                          date={adr.created_at.toDate()}
                        ></ReactTimeago>
                      </TableCell>
                      <TableCell>
                        <Status value={adr.status} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default TechnicalReports;
