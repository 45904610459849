import React from 'react';
import Icon, { Stack } from '@mdi/react';

const CompliancePalIcon = ({ size }) => (
  <Stack size={size} title="CompliancePal">
    <Icon
      path="M23.371 13.955 18.899 13.955C18.569 15.092 17.956 16.164 17.06 17.06 14.221 19.898 9.619 19.898 6.781 17.06 3.942 14.221 3.942 9.619 6.781 6.781 9.619 3.942 14.221 3.942 17.06 6.781 17.956 7.677 18.569 8.748 18.899 9.885L23.371 9.885C22.97 7.618 21.894 5.448 20.143 3.697 15.602-.845 8.238-.845 3.697 3.697-.845 8.238-.845 15.602 3.697 20.143 8.238 24.685 15.602 24.685 20.143 20.143 21.894 18.392 22.97 16.222 23.371 13.955Z"
      size={size}
      color="#376da5"
    />
    <Icon
      path="M13.976 9.864C15.111 11 15.111 12.84 13.976 13.976 12.84 15.111 11 15.111 9.864 13.976 8.729 12.84 8.729 11 9.864 9.864 11 8.729 12.84 8.729 13.976 9.864M4.652 20.999C5.884 21.988 7.271 22.69 8.722 23.103L8.722 18.449C11.435 19.778 14.804 19.315 17.06 17.06 19.898 14.221 19.898 9.619 17.06 6.781 14.221 3.942 9.619 3.942 6.781 6.781 5.361 8.2 4.652 10.06 4.652 11.92Z"
      size={size}
      color="#feb009"
    />
  </Stack>
);

export default CompliancePalIcon;
