import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@material-ui/core';
import { firestore } from '../../../stores/firebaseInit';
import { collections } from '../../../utils/db';

export const SelectRepoStep = ({ account, next }) => {
  const [selected, setSelected] = useState(null);
  const [requested, setRequested] = useState(false);

  const [repositories, setRepositories] = useState([]);

  useEffect(() => {
    let disposer = () => {};

    if (account) {
      disposer = firestore
        .collection(collections.INTEGRATIONS)
        .doc(account.integrations.github)
        .onSnapshot((snap) => {
          const repos = snap.data().repositories;

          setRepositories(repos);
        });
    }

    return disposer;
  }, [account]);

  const theme = useTheme();

  return (
    <React.Fragment>
      <Typography>
        The architecture description resides in the following repository:
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox"></TableCell>
            <TableCell>Repository</TableCell>
            <TableCell>Private</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {repositories.map((repo, index) => {
            return (
              <TableRow
                hover
                key={repo.full_name}
                onClick={() => {
                  setSelected(selected === index ? false : index);
                }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selected === index}
                    disabled={requested}
                  ></Checkbox>
                </TableCell>
                <TableCell>{repo.full_name}</TableCell>
                <TableCell>{repo.private ? 'yes' : 'no'}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <div>
        <Button
          color="primary"
          disabled={typeof selected !== 'number' || requested}
          style={{
            marginBottom: theme.spacing(1),
            marginTop: theme.spacing(1),
          }}
          variant="contained"
          onClick={async () => {
            try {
              setRequested(true);
              setSelected(null);
              await next(repositories[selected]);
            } finally {
              setRequested(false);
            }
          }}
        >
          Configure
        </Button>
      </div>
    </React.Fragment>
  );
};
