export const collections = {
  ACCOUNTS: 'accounts',
  ASSETS: 'assets',
  CHECKS: 'checks',
  DECISIONS: 'decisions',
  INSTALLATIONS: 'installations',
  INTEGRATIONS: 'integrations',
  NAMESPACES: 'namespaces',
  PROFILES: 'profiles',
  PROJECTS: 'projects',
  PULLS: 'pulls',
  SOFTWARE_ITEMS: 'software-items',
};
