import { firestore } from '../stores/firebaseInit';

export const myTeamSetupUrl = () => '/setup/team';

export const softwareItemSetupUrl = (installationId, itemId) =>
  `/t/${installationId}/setup/software-item/${itemId}`;

export const softwareItemUrl = (id) =>
  `/software/items/${encodeURIComponent(id)}`;

export const softwareItemCheckUrl = (id) => `${softwareItemUrl(id)}/checks`;
export const softwareItemCheckUrl2 = async (installation_id, id) => {
  const installation = await firestore
    .collection('installations')
    .doc(installation_id)
    .get();

  if (installation.exists) {
    return `${softwareItemUrl(id)}/checks`;
  }

  return null;
};

export const namespaceCreateProjectUrl = (namespace) =>
  `/u/${namespace}/create/project`;
export const namespaceOverviewUrl = (namespace) => `/u/${namespace}/overview`;

export const namespaceProjectsUrl = (namespace) => `/u/${namespace}/projects`;

export const namespaceProjectUrl = (namespace, project) =>
  `/u/${namespace}/projects/${project}`;

export const projectProcessesUrl = (namespace, project) =>
  `/u/${namespace}/projects/${project}/processes`;

export const projectArchitectureUrl = (namespace, project) =>
  `/u/${namespace}/projects/${project}/architecture`;

export const projectArchitectureChangeRequestUrl = (
  namespace,
  project,
  number
) =>
  `/u/${namespace}/projects/${project}/architecture/change-requests/${number}`;

export const projectSettingsUrl = (namespace, project) =>
  `/u/${namespace}/projects/${project}/settings`;

export const projectArchitectureSetupUrl = (namespace, project) =>
  `/u/${namespace}/projects/${project}/setup/architecture`;

export const projectSoftwareItemsUrl = (namespace, project) =>
  `/u/${namespace}/projects/${project}/software-items`;

export const projectSoftwareItemUrl = (namespace, project, item) =>
  `/u/${namespace}/projects/${project}/software-items/${item}`;

export const projectTechnicalFilesUrl = (namespace, project) =>
  `/u/${namespace}/projects/${project}/records`;
export const projectTechnicalFileUrl = (namespace, project, id) =>
  `/u/${namespace}/projects/${project}/records/${id}`;

export const namespaceSettingsUrl = (namespace) => `/u/${namespace}/settings`;

export const teamUrl = (installation) => `/t/${installation}`;

export const teamArchitectureUrl = (installation) =>
  `/t/${installation}/architecture`;

export const teamArchitectureSetupUrl = (installation) =>
  `/t/${installation}/setup/architecture`;

export const teamOverviewUrl = (installation) => `/t/${installation}/overview`;
export const teamSettingsUrl = (installation) => `/t/${installation}/settings`;
export const teamProcessesUrl = (installation) =>
  `/t/${installation}/processes`;
export const teamReportsUrl = (installation) => `/t/${installation}/reports`;
export const teamTechnicalFilesUrl = (installation) =>
  `/t/${installation}/technical-files`;

export const teamSoftwareItemsUrl = (installation) =>
  `/t/${installation}/software-items`;

export const teamSoftwareUrl = (installation) => `/t/${installation}/software`;

export const teamReportUrl = (installation, id) =>
  `/t/${installation}/technical-files/${id}`;

export const teamSoftwareItemUrl = (installation, id) =>
  `/t/${installation}/software-items/${encodeURIComponent(id)}`;

export const teamSoftwareItemCheckUrl = (installation, id) =>
  `${teamSoftwareItemUrl(installation, id)}/checks`;

export const teamSoftwareItemPullsUrl = (installation, id) =>
  `${teamSoftwareItemUrl(installation, id)}/pulls`;

export const teamSoftwareItemPullUrl = (installation, id, pull) =>
  `${teamSoftwareItemUrl(installation, id)}/pulls/${pull}`;
