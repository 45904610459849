import React, { Fragment } from 'react';
import Page from '../../components/pages/Project';
import PageHeader from '../../components/PageHeader';
import {
  Grid,
  Typography,
  useTheme,
  Container,
  Paper,
  Avatar,
  Divider,
  Button,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core';
import Icon from '@mdi/react';
import {
  mdiChevronRight,
  mdiStateMachine,
  mdiFileCadBox,
  mdiCodeBracesBox,
} from '@mdi/js';
import {
  projectProcessesUrl,
  projectArchitectureUrl,
  projectSoftwareItemsUrl,
  projectArchitectureSetupUrl,
} from '../../utils/urls';
import { Link } from '@reach/router';
import { useProject } from '../../stores/projectProvider';

const Arrow = () => {
  const theme = useTheme();

  return (
    <Grid item>
      <Icon size={2} path={mdiChevronRight} color={theme.palette.grey[500]} />
    </Grid>
  );
};

const Step = ({ content, disabled = false, href = null, path }) => {
  const theme = useTheme();

  return (
    <Grid
      item
      xs={2}
      style={{
        height: theme.spacing(12),
      }}
    >
      <Paper
        style={{
          overflow: 'visible',
          position: 'relative',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {path && (
          <Avatar
            style={{
              border: '1px solid lightgrey',
              backgroundColor: 'white',
              top: -20,
              right: -20,
              position: 'absolute',
            }}
          >
            <Icon size={0.75} path={path} color={theme.palette.grey[600]} />
          </Avatar>
        )}
        {content.map((line) => (
          <Typography
            component={href ? Link : Typography}
            to={href}
            key={line}
            align="center"
            color={disabled ? 'textSecondary' : 'textPrimary'}
          >
            {line}
          </Typography>
        ))}
      </Paper>
    </Grid>
  );
};

const GettingStarted = ({ namespace, project }) => {
  return project ? (
    project.architecture.state !== 'none' ? null : (
      <Fragment>
        <Typography variant="h6" gutterBottom>
          Getting started
        </Typography>
        <Card
          style={{
            width: 400,
          }}
        >
          <CardContent>
            <Typography>
              Your project needs an architecture repository.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              component={Link}
              to={projectArchitectureSetupUrl(namespace, project.name)}
              color="primary"
            >
              Configure
            </Button>
          </CardActions>
        </Card>
      </Fragment>
    )
  ) : null;
};

const Content = ({ namespace, project }) => {
  const theme = useTheme();

  const [projectData] = useProject();

  console.log(projectData);

  return (
    <Fragment>
      <PageHeader title="Overview" />
      <Container>
        <Grid
          container
          direction="row"
          alignItems="center"
          alignContent="center"
          spacing={2}
          style={{
            paddingTop: theme.spacing(8),
          }}
        >
          <Step
            content={['Development', 'planning']}
            path={mdiStateMachine}
            href={projectProcessesUrl(namespace, project)}
          />
          <Arrow />
          <Step content={['Requirements', 'analysis']} disabled />
          <Arrow />
          <Step
            content={['Architectural', 'design']}
            path={mdiFileCadBox}
            href={projectArchitectureUrl(namespace, project)}
          />
          <Arrow />
          <Step
            content={['Detailed', 'design']}
            path={mdiCodeBracesBox}
            href={projectSoftwareItemsUrl(namespace, project)}
          />
          <Arrow />
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          alignContent="center"
          spacing={2}
          style={{
            paddingTop: theme.spacing(8),
          }}
        >
          <Arrow />
          <Step
            content={['Unit implementation', 'and verification']}
            disabled
          />
          <Arrow />
          <Step content={['Integration', 'and testing']} disabled />
          <Arrow />
          <Step content={['System testing']} disabled />
          <Arrow />
          <Step content={['Release']} disabled />
        </Grid>
        <Divider
          style={{
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
          }}
        ></Divider>
        <GettingStarted namespace={namespace} project={projectData} />
      </Container>
    </Fragment>
  );
};

export default (props) => {
  return (
    <Page {...props} title="Overview">
      <Content {...props} />
    </Page>
  );
};
