import dagreD3 from 'dagre-d3';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import yellow from '@material-ui/core/colors/yellow';

import React from 'react';
import ReactDOM from 'react-dom';
import { TooltipLabel } from './TooltipLabel';

const fill = (cls) => {
  switch (cls) {
    case 'A':
      return green['200'];
    case 'B':
      return yellow['200'];
    case 'C':
      return red['200'];
    default:
      return 'lightgrey';
  }
};

export default (parent, bbox, node) => {
  const { height, width } = bbox;

  parent
    .insert('rect', ':first-child')
    .attr('x', -width * 0.5)
    .attr('y', -height * 0.5)
    .attr('width', 1 * width)
    .attr('height', 1 * height)
    .attr('stroke', '#333')
    .attr('fill', fill(node.class));

  if (!node.external) {
    // parent.classed('item', true);
    node.onClick && parent.on('click', node.onClick);
  }

  parent.on('mouseenter', (ev) => {
    node.onClick && !node.external && parent.classed('highlight', true);

    node.type !== 'system' &&
      ReactDOM.hydrate(
        <TooltipLabel title={node.description || ''} name={node.name} open />,
        document.getElementById(`element-${node.id}`)
      );
  });

  parent.on('mouseleave', () => {
    node.onClick && !node.external && parent.classed('highlight', false);

    node.type !== 'system' &&
      ReactDOM.hydrate(
        <TooltipLabel
          title={node.description || ''}
          name={node.name}
          open={false}
        />,
        document.getElementById(`element-${node.id}`)
      );
    //node.callbacks.hide(node.id);
  });

  if (node.onClick && !node.external) {
    parent.on('click', () => {
      ReactDOM.hydrate(
        <TooltipLabel
          title={node.description || ''}
          name={node.name}
          open={false}
        />,
        document.getElementById(`element-${node.id}`)
      );
      node.onClick();
    });
  }

  node.intersect = (point) => dagreD3.intersect.rect(node, point);

  return parent;
};
