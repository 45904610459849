import React, { createContext } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { useProfile } from './profileProvider';
import { firestore } from './firebaseInit';
import { collections } from '../utils/db';

export const AccountContext = createContext(null);

export const AccountProvider = ({ children }) => {
  const [namespace, setNamespace] = useState(undefined);
  const [id, setId] = useState(null);
  const [account, setAccount] = useState(null);

  const [profile] = useProfile();

  useEffect(() => {
    if (namespace && profile) {
      const { id } = profile.teams.find((team) => team.namespace === namespace);

      setId(id);
    } else {
      setId(null);
    }
  }, [namespace, profile]);

  useEffect(() => {
    let disposer = () => {};

    if (id) {
      disposer = firestore
        .collection(collections.ACCOUNTS)
        .doc(id)
        .onSnapshot((snap) => {
          setAccount(snap.data());
        });
    } else {
      setAccount(null);
    }

    return () => disposer();
  }, [id]);

  return (
    <AccountContext.Provider value={[{ ...account, id }, { setNamespace }]}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = (namespace) => {
  const [value, { setNamespace }] = useContext(AccountContext);
  setNamespace(namespace);

  return [value];
};
