import React, { useState, useEffect } from 'react';

//import TeamIcon from 'mdi-material-ui/AccountMultiple';
import {
  Card,
  CardContent,
  withStyles,
  Typography,
  Avatar,
  Grid,
  Tooltip,
  Divider,
  useTheme,
  Button,
} from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { Link, navigate } from '@reach/router';
import { auth } from '../../../stores/firebaseInit';
import { useProfile } from '../../../stores/profileProvider';

import { version } from '../../../../package.json';
import { useUiContext } from '../../../stores/UiProvider';

const styles = (theme) => ({
  avatar: {
    height: 200,
    width: 200,
    border: '4px solid lightgray',
    margin: 'auto',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(6),
  },
  card: {
    width: 350,
    // height: 500,
    margin: 'auto',
  },
  content: {
    paddingTop: 0,
    flex: '1 0 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  email: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
    marginBottom: theme.spacing(5),
    '&:hover::after': {
      content: '""',
    },
  },
  github: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    margin: 'auto',
  },
  name: {},
  space: {
    marginRight: theme.spacing(1),
  },
  team: {
    margin: theme.spacing(0.5),
  },
  teams: {
    marginBottom: theme.spacing(1),
  },
  verified: {
    color: green[400],
    marginLeft: theme.spacing(1),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -20,
    marginLeft: -20,
  },
  startTeam: {
    margin: 'auto',
    marginTop: theme.spacing(4),
  },
  teamRow: {
    height: 40,
  },
});

const LogoutButton = () => {
  const [, actions] = useUiContext();

  return (
    <Button
      style={{
        textTransform: 'none',
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      onClick={() => {
        console.log('logging out user');
        actions.reset();
        auth.signOut().then(() => navigate('/'));
      }}
      variant="outlined"
    >
      Logout
    </Button>
  );
};

const Profile = ({ classes }) => {
  const theme = useTheme();
  const [user, setUser] = useState(null);
  const [teams, setTeams] = useState([]);

  const [profile] = useProfile();

  useEffect(() => {
    console.log(profile);
    profile && setTeams(profile.teams);
  }, [profile]);

  useEffect(() => {
    profile &&
      setUser((user) => ({
        ...user,
        profile: auth.currentUser.providerData[0],
        hasOwnInstallation:
          profile.installation && profile.installation.status === 'installed',
      }));
  }, [profile]);

  console.log(profile);

  return user ? (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Avatar
          className={classes.avatar}
          alt={user.profile.displayName}
          src={user.profile.photoURL}
        />
        <Typography variant="h5" align="center" className={classes.name}>
          {user.profile.displayName}
        </Typography>
        <Typography align="center" className={classes.email}>
          {user.profile.email}
        </Typography>
        <Divider />
        <Typography
          align="center"
          className={classes.teams}
          component={Link}
          to="/"
        >
          My teams
        </Typography>
        <Grid
          container
          justify="center"
          direction="row"
          className={classes.teamRow}
        >
          {teams.map((installation) => (
            <Grid item key={installation.id} className={classes.team}>
              <Tooltip title={installation.name}>
                <Avatar src={installation.avatar} />
              </Tooltip>
            </Grid>
          ))}
        </Grid>
        <Divider
          style={{
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        />
        <LogoutButton />
        <Divider
          style={{
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        />
        <Typography variant="body2" align="center">
          App info: v{version}
        </Typography>
      </CardContent>
    </Card>
  ) : null;
};

export default withStyles(styles)(Profile);
