import {
  mdiHome,
  mdiStateMachine,
  mdiFileCadBox,
  mdiFileCabinet,
  mdiCodeBracesBox,
  mdiSettings,
} from '@mdi/js';
import {
  namespaceProjectUrl,
  projectProcessesUrl,
  projectArchitectureUrl,
  projectSoftwareItemsUrl,
  projectTechnicalFilesUrl,
  projectSettingsUrl,
} from '../../../utils/urls';
export const menuItems = (namespace, project) => [
  {
    href: namespaceProjectUrl(namespace, project),
    path: mdiHome,
    text: 'Home',
  },
  {
    href: projectSettingsUrl(namespace, project),
    path: mdiSettings,
    text: 'Settings',
    divider: true,
    role: 'owners',
  },
  {
    href: projectProcessesUrl(namespace, project),
    path: mdiStateMachine,
    text: 'Processes',
    divider: true,
  },
  {
    href: projectArchitectureUrl(namespace, project),
    path: mdiFileCadBox,
    text: 'Architecture',
  },
  {
    href: projectSoftwareItemsUrl(namespace, project),
    path: mdiCodeBracesBox,
    text: 'Software items',
    divider: true,
  },
  {
    href: projectTechnicalFilesUrl(namespace, project),
    path: mdiFileCabinet,
    text: 'Records',
    divider: true,
  },
];
