import React, { useEffect, useState } from 'react';
import Page from '../../components/pages/Account';
import {
  Container,
  Grid,
  Avatar,
  useTheme,
  Typography,
  Button,
  Card,
  CardContent,
  CardActions,
} from '@material-ui/core';
import { useProfile } from '../../stores/profileProvider';
import { Link } from '@reach/router';
import { namespaceCreateProjectUrl } from '../../utils/urls';
import { useAccount } from '../../stores/accountProvider';

const AccountOverview = ({ namespace }) => {
  const theme = useTheme();
  const [needsIntegrationSetup, setNeedsIntegrationSetup] = useState(true);
  const [profile] = useProfile();
  const [account] = useAccount(namespace);

  const team = profile.teams.find((team) => team.namespace === namespace);

  useEffect(() => {
    setNeedsIntegrationSetup(
      () => !(account && account.integrations && account.integrations.github)
    );
  }, [account]);

  return (
    <Page namespace={namespace} title="Overview">
      <Container>
        <Grid
          container
          style={{
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
          }}
          spacing={4}
        >
          <Grid item>
            <Avatar
              src={team.avatar}
              alt={team.name}
              style={{
                width: theme.spacing(16),
                height: theme.spacing(16),
              }}
            ></Avatar>
          </Grid>
          <Grid item md={6}>
            <Typography variant="h4" gutterBottom>
              {team.name}
            </Typography>
            <Typography>{team.personal ? 'personal' : 'team'}</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              component={Link}
              disabled={needsIntegrationSetup}
              to={namespaceCreateProjectUrl(namespace)}
            >
              Create Project
            </Button>
          </Grid>
        </Grid>
        <Grid container>
          {needsIntegrationSetup && (
            <Grid item md={6}>
              <Card>
                <CardContent>
                  <Typography>Configure your first git integration</Typography>
                </CardContent>
                <CardActions>
                  <Button color="primary">Configure</Button>
                </CardActions>
              </Card>
            </Grid>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default AccountOverview;
