import React, { useState, useEffect } from 'react';

import Page from '../../components/pages/Project';
import { firestore } from '../../stores/firebaseInit';
import PageHeader from '../../components/PageHeader';
import {
  Container,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Card,
  CardContent,
} from '@material-ui/core';
import { useParams } from '@reach/router';
import { useAccount } from '../../stores/accountProvider';
import { collections } from '../../utils/db';
import SafetyClass from '../../components/chips/SafetyClass';
import { Link } from '@reach/router';
import { projectSoftwareItemUrl } from '../../utils/urls';

const tabs = ['Catalog', 'Decomposition'];

const SoftwareItemsCard = ({ items, soup = false }) => {
  const { namespace, project } = useParams();

  return (
    <Card>
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none">Class</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Type</TableCell>
              {soup && <TableCell>SOUP</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {items
              .map((item) => item.content)
              .map((item) => {
                return (
                  <TableRow key={item.id}>
                    <TableCell padding="none">
                      <SafetyClass cls={item.class} compact />
                    </TableCell>
                    <TableCell>
                      <Link
                        to={projectSoftwareItemUrl(namespace, project, item.id)}
                      >
                        {item.id}
                      </Link>
                    </TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.type}</TableCell>
                    {soup && (
                      <TableCell>
                        {item.location === 'external' ? 'yes' : null}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const SoftwareItems = ({ namespace, project, tabValue }) => {
  const [items, setItems] = useState([]);

  const [account] = useAccount(namespace);

  useEffect(() => {
    if (account && account.id && project) {
      return firestore
        .collection(collections.ACCOUNTS)
        .doc(account.id)
        .collection(collections.PROJECTS)
        .doc(project)
        .collection(collections.ASSETS)
        .onSnapshot((snap) => {
          setItems(
            snap.docs
              .map((doc) => doc.data())
              .filter((data) => data.type !== 'architecture')
              .map((data) => ({
                ...data,
                isSoup: data.content.location === 'external',
              }))
          );
        });
    }
  }, [account, project]);

  return (
    <Page namespace={namespace} project={project} title="Software Items">
      <PageHeader title="Software items" tabs={tabs} value={tabValue} />
      <Container>
        {/* <SoftwareItemsCard
          items={items}
          teamId={installation_id}
          variant={tabValue === 0 && 'list'}
        /> */}
        <SoftwareItemsCard items={items} />
      </Container>
    </Page>
  );
};

export default SoftwareItems;
