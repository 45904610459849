import React from 'react';
import clsx from 'clsx';
import { Link, useParams } from '@reach/router';
import { Icon } from '@mdi/react';
import {
  List,
  Drawer,
  ListItem,
  ListItemIcon,
  Tooltip,
  useTheme,
  ListItemText,
  Divider,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { useUiContext } from '../../../stores/UiProvider';
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js';
import { useAccount } from '../../../stores/accountProvider';
import { auth } from '../../../stores/firebaseInit';

const useStyles = makeStyles((theme) => {
  const drawerWidth = 240;

  return {
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1,
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
  };
});

export const DrawerMenu = ({ items }) => {
  const theme = useTheme();
  const classes = useStyles(theme);

  const { namespace } = useParams();
  const [account] = useAccount(namespace);

  const [{ openNavigationDrawer }, actions] = useUiContext();

  const handleDrawerOpen = () => {
    actions.openNavigationDrawer();
  };

  const handleDrawerClose = () => {
    actions.closeNavigationDrawer();
  };

  return (
    <Drawer
      variant="permanent"
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: openNavigationDrawer,
        [classes.drawerClose]: !openNavigationDrawer,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: openNavigationDrawer,
          [classes.drawerClose]: !openNavigationDrawer,
        }),
      }}
    >
      <div className={classes.toolbar}></div>
      <List>
        {items &&
          items.map(({ href, path, role, text }) => {
            const disabled = role
              ? !(
                  account &&
                  account.__abac &&
                  account.__abac[role][auth.currentUser.uid]
                )
              : false;

            return (
              <ListItem
                button
                key={text}
                component={Link}
                to={href}
                disabled={disabled}
              >
                {openNavigationDrawer ? (
                  <React.Fragment>
                    <ListItemIcon>
                      <Icon
                        size={1}
                        color={theme.palette.grey[600]}
                        path={path}
                      ></Icon>
                    </ListItemIcon>
                    <ListItemText primary={text} />
                  </React.Fragment>
                ) : (
                  <Tooltip title={text} placement="right">
                    <ListItemIcon>
                      <Icon
                        size={1}
                        color={theme.palette.grey[600]}
                        path={path}
                      ></Icon>
                    </ListItemIcon>
                  </Tooltip>
                )}
              </ListItem>
            );
          })}
      </List>
      <div style={{ backgroundColor: 'white', flexGrow: 1 }} />
      <Divider />
      {openNavigationDrawer ? (
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <Icon
                path={mdiChevronRight}
                color={theme.palette.grey[600]}
                size={1}
              />
            ) : (
              <Icon
                path={mdiChevronLeft}
                color={theme.palette.grey[600]}
                size={1}
              />
            )}
          </IconButton>
        </div>
      ) : (
        <List>
          <ListItem button onClick={handleDrawerOpen}>
            <ListItemIcon>
              <Icon
                path={mdiChevronRight}
                color={theme.palette.grey[600]}
                size={1}
              />
            </ListItemIcon>
            <ListItemText />
          </ListItem>
        </List>
      )}
    </Drawer>
  );
};
