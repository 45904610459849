import React, { createContext, useState, useContext, useEffect } from 'react';

// @ts-ignore
import { useParams } from '@reach/router';
import { firestore } from './firebaseInit';
import { collections } from '../utils/db';
import { useAccount } from './accountProvider';

export const ProjectContext = createContext(null);

export const ProjectProvider = ({ children }) => {
  const { namespace, project: projectName } = useParams();
  const [project, setProject] = useState(null);

  const [account] = useAccount(namespace);

  useEffect(() => {
    let disposer = () => {};

    if (account.id && projectName) {
      disposer = firestore
        .collection(collections.ACCOUNTS)
        .doc(account.id)
        .collection(collections.PROJECTS)
        .doc(projectName)
        .onSnapshot((snap) => {
          setProject(snap.data());
        });
    }

    return disposer;
  }, [account, projectName]);

  return (
    <ProjectContext.Provider value={[project]}>
      {project ? children : null}
    </ProjectContext.Provider>
  );
};

export const useProject = () => useContext(ProjectContext);
