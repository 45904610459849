import React from 'react';
import Helmet from 'react-helmet';
import {
  withStyles,
  Stepper,
  CardContent,
  CardHeader,
  Step,
  StepLabel,
  Card,
  Typography,
  Button,
  Snackbar,
  SnackbarContent,
  Container,
} from '@material-ui/core';
import Page from '../components/pages/Account';
import { Link } from '@reach/router';
import { firestore, auth, functions } from '../stores/firebaseInit';

const styles = (theme) => ({
  card: {
    margin: theme.spacing(),
  },
  instructions: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.error.dark,
  },
});

class TeamSetupCard extends React.Component {
  state = {
    buttonDisabled: false,
    snackOpen: false,
  };

  updateActiveStep(status) {
    let activeStep = this.state.activeStep;
    switch (status) {
      case 'none':
        activeStep = 0;
        break;
      case 'requested':
        activeStep = 1;
        break;
      case 'granted':
        activeStep = 2;
        break;
      case 'installed':
        activeStep = 3;
        break;
      default:
    }
    this.setState({
      activeStep,
    });
  }

  componentDidMount() {
    this.dispose = firestore
      .collection('profiles')
      .doc(auth.currentUser.providerData[0].uid)
      .onSnapshot((snap) => {
        const { installation } = snap.data();

        console.log(installation);
        this.updateActiveStep(
          installation !== undefined ? installation.status : null
        );

        if (installation.id) {
          this.setState({
            ...this.state,
            installation_id: installation.id,
          });
        }
      });

    /*
    console.log('patch', store);
    this.dispose = onPatch(store, patch => {
      if (patch.path === '/user/installation') {
        this.updateActiveStep(
          patch.value !== undefined ? patch.value.status : null
        );
      }

      if (patch.path === '/user/installation/status') {
        this.updateActiveStep(patch.value);
      }
    });

    this.updateActiveStep(store.user.installationStatus());
    */
  }

  componentWillUnmount() {
    this.dispose();
  }

  render() {
    const { classes } = this.props;
    const { activeStep, buttonDisabled } = this.state;

    const steps = [
      {
        label: 'Request invite',
        content:
          'Use CompliancePal on your GitHub assets. Join the beta program!',
        action: (
          <Button
            disabled={buttonDisabled}
            color="primary"
            variant="contained"
            onClick={() => {
              functions
                .httpsCallable('httpsOnCallTeamPermissionRequest')({})
                .catch((error) => {
                  console.log(error.details);
                  this.setState({
                    snackMessage: error.details,
                    snackOpen: true,
                  });
                })
                .finally(() => {
                  this.setState({
                    buttonDisabled: false,
                  });
                });
              this.setState({
                buttonDisabled: true,
              });
            }}
          >
            Request
          </Button>
        ),
      },
      {
        label: 'Request approval',
        content:
          "Your request is being processed. We'll send a notification when you can proceed",
      },
      {
        label: 'Install GitHub app',
        content:
          'Congratulations! Proceed to install CompliancePal application to GitHub',
        action: (
          <Button
            href={`https://github.com/apps/${process.env.REACT_APP_FIREBASE_GITHUB_APP_NAME}`}
            target="_"
            color="primary"
            variant="contained"
          >
            Install
          </Button>
        ),
      },
      {
        label: 'Manage team',
        content: 'Your team is ready!',
        action: (
          <Button
            component={Link}
            to={`/profile/teams/${this.state.installation_id}`}
            color="primary"
            variant="contained"
          >
            Start
          </Button>
        ),
      },
    ];

    if (activeStep === undefined) return null;

    return (
      <Card>
        <CardHeader
          title="My team"
          subheader="Create team by joining beta program to run CompliancePal activities on my GitHub assets"
        />
        <CardContent>
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            <Typography className={classes.instructions}>
              {steps[activeStep].content}
            </Typography>
            {steps[activeStep].action}
          </div>
        </CardContent>
        <Snackbar
          autoHideDuration={5000}
          open={this.state.snackOpen}
          onClose={() => {
            this.setState({
              snackOpen: false,
            });
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <SnackbarContent
            message={this.state.snackMessage}
            className={classes.message}
          />
        </Snackbar>
      </Card>
    );
  }
}
const TeamSetup = (props) => {
  return (
    <Page>
      <Helmet>
        <title>Setup my team</title>
      </Helmet>
      <Container>
        <TeamSetupCard {...props} />
      </Container>
    </Page>
  );
};

export default withStyles(styles)(TeamSetup);
