// @ts-nocheck
import React, { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Brand from '../common/Brand';
import { Avatar, Typography, makeStyles, useTheme } from '@material-ui/core';

import { Link } from '@reach/router';
import { namespaceOverviewUrl, namespaceProjectUrl } from '../../../utils/urls';
import { useUiContext } from '../../../stores/UiProvider';
import { useProfile } from '../../../stores/profileProvider';
import ErrorBoundary from '../../ErrorBoundary';
import Helmet from 'react-helmet';

import { menuItems } from './menuItems';
import { useAccount } from '../../../stores/accountProvider';
import { DrawerMenu } from '../common/Drawer';
import { ProfileButton } from '../common/ProfileButton';
import { ProjectProvider } from '../../../stores/projectProvider';

const useStyles = makeStyles((theme) => {
  const drawerWidth = 240;

  return {
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      //width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  };
});

/**
 *
 * @param {object} props
 * @param {object} props.children
 * @param {string} [props.namespace]
 * @param {string} [props.project]
 * @param {string} [props.team]
 * @param {string} [props.title]
 */
export function ProjectPage({ children, namespace, project, title }) {
  const theme = useTheme();
  const classes = useStyles(theme);

  const [{ openNavigationDrawer }] = useUiContext();

  const [profile] = useProfile();

  const [account] = useAccount(namespace);

  const [namespaceInfo, setNamespaceInfo] = useState(null);

  useEffect(() => {
    profile &&
      setNamespaceInfo(
        profile.teams.find((team) => team.namespace === namespace)
      );
  }, [namespace, profile]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{`${project} - ${namespace} - ${title} - CompliancePal console`}</title>
      </Helmet>
      <AppBar
        color="default"
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: openNavigationDrawer,
        })}
      >
        <Toolbar>
          <Brand></Brand>
          {account && (
            <Fragment>
              <Typography
                variant="h5"
                color="textSecondary"
                style={{
                  marginLeft: theme.spacing(1),
                  marginRight: theme.spacing(1),
                  fontWeight: 'lighter',
                }}
              >
                /
              </Typography>
              <Link
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                }}
                to={namespaceOverviewUrl(namespace)}
              >
                <Avatar
                  src={namespaceInfo && namespaceInfo.avatar}
                  style={{
                    width: 30,
                    height: 30,
                    marginRight: theme.spacing(1),
                  }}
                />
                <Typography variant="h6" color="textSecondary">
                  {namespace}
                </Typography>
              </Link>
              <Typography
                variant="h5"
                color="textSecondary"
                style={{
                  marginLeft: theme.spacing(1),
                  marginRight: theme.spacing(1),
                  fontWeight: 'lighter',
                }}
              >
                /
              </Typography>
              <Link
                to={namespaceProjectUrl(namespace, project)}
                style={{
                  textDecoration: 'none',
                }}
              >
                <Typography variant="h6" color="textSecondary">
                  {project}
                </Typography>
              </Link>
            </Fragment>
          )}
          <div
            style={{
              flexGrow: 1,
            }}
          />
          <ProfileButton />
        </Toolbar>
      </AppBar>
      {namespace && project && (
        <DrawerMenu items={menuItems(namespace, project)} />
      )}
      <main className={classes.content}>
        <div className={classes.toolbar}></div>
        <ErrorBoundary>
          <ProjectProvider>{children}</ProjectProvider>
        </ErrorBoundary>
      </main>
    </div>
  );
}

export default ProjectPage;
