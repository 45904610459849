import React, { useEffect, useState } from 'react';

import { Page } from '../components/pages/Account';
import {
  withStyles,
  Card,
  CardHeader,
  Avatar,
  Grid,
  CardActions,
  Button,
  Container,
  CardContent,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Link } from '@reach/router';
import { namespaceOverviewUrl } from '../utils/urls';
import { useProfile } from '../stores/profileProvider';
import Helmet from 'react-helmet';
import { firestore } from '../stores/firebaseInit';
import { collections } from '../utils/db';
import Icon from '@mdi/react';
import { mdiGithubCircle } from '@mdi/js';

const styles = () => ({
  card: {},
});

const Namespace = ({ installation: team, caption }) => {
  const theme = useTheme();
  const [members, setMembers] = useState(0);
  const [integration, setIntegration] = useState(false);

  useEffect(() => {
    firestore
      .collection(collections.ACCOUNTS)
      .doc(team.id.toString())
      .get()
      .then((doc) => {
        const data = doc.data();

        console.log(data);
        setMembers(Object.keys(data.__abac.members).length);
        if (data.integrations.github) setIntegration(true);
        // setIntegration(
        //   data.team.members.find((member) => member.id === data.team.owner)
        //     .login
        // );
      });
  }, [team]);

  console.log(team);

  return (
    <Card>
      <CardHeader
        title={team.name}
        titleTypographyProps={{
          variant: 'h5',
        }}
        subheader="Personal account"
        avatar={<Avatar src={team.avatar} />}
      />
      <CardContent>
        <Grid container>
          <Grid
            item
            style={{
              borderRight: '1px lightgrey solid',
              paddingRight: theme.spacing(2),
              marginRight: theme.spacing(2),
            }}
          >
            <Typography color="textSecondary" variant="overline">
              Members
            </Typography>
            <Typography>{members}</Typography>
          </Grid>
          <Grid item>
            <Typography color="textSecondary" variant="overline">
              Git integration
            </Typography>
            {integration ? (
              <Typography style={{ display: 'flex', alignItems: 'center' }}>
                <Icon
                  size={0.8}
                  path={mdiGithubCircle}
                  style={{ marginRight: theme.spacing(0.5) }}
                />
                integration-user
              </Typography>
            ) : (
              <Typography>Not connected</Typography>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Button
          color="primary"
          to={namespaceOverviewUrl(team.namespace)}
          component={Link}
        >
          {caption}
        </Button>
      </CardActions>
    </Card>
  );
};

const Teams = () => {
  const [teams, setTeams] = useState([]);

  const [profile] = useProfile();

  useEffect(() => {
    setTeams(profile ? profile.teams : []);
  }, [profile]);

  return (
    <Page>
      <Helmet>
        <title>CompliancePal console</title>
      </Helmet>
      <Container>
        <Grid container spacing={2}>
          {teams.map((team) => (
            <Grid item key={team.id} xs={12} sm={6} md={4}>
              <Namespace installation={team} caption="View" />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
};

export default withStyles(styles)(Teams);
