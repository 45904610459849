import React, { useEffect, useState, Fragment } from 'react';
import Page from '../../components/pages/Project';
import TagIcon from 'mdi-material-ui/TagOutline';
import {
  Container,
  Card,
  CardContent,
  Grid,
  Typography,
  CardActions,
  Button,
  useTheme,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
} from '@material-ui/core';
import ArchitectureViewer from '../ArchitectureEditor/Viewer';
import PageHeader from '../../components/PageHeader';
import { Link } from '@reach/router';
import {
  projectArchitectureSetupUrl,
  projectArchitectureChangeRequestUrl,
} from '../../utils/urls';
import { useProject } from '../../stores/projectProvider';
import { firestore } from '../../stores/firebaseInit';
import { collections } from '../../utils/db';
import { useAccount } from '../../stores/accountProvider';
import ReactTimeago from 'react-timeago';
import { useParams } from '@reach/router';

const ChangeRequests = ({ accountId, projectId, assetId, open = false }) => {
  const [loaded, setLoaded] = useState(false);
  const [pulls, setPulls] = useState([]);

  const { namespace } = useParams();

  useEffect(() => {
    let disposer = () => {};

    if (assetId) {
      disposer = firestore
        .collection(collections.ACCOUNTS)
        .doc(accountId)
        .collection(collections.PROJECTS)
        .doc(projectId)
        .collection(collections.ASSETS)
        .doc(assetId)
        .collection(collections.PULLS)
        .where('state', '==', open ? 'open' : 'closed')
        .onSnapshot((snap) => {
          const pulls = snap.docs.map((doc) => doc.data());

          setPulls(pulls);
          setLoaded(true);
        });
    }

    return disposer;
  }, [accountId, projectId, assetId, open]);

  return (
    <Container>
      {loaded ? (
        <Card>
          <CardContent>
            {pulls.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell padding="none">Id</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>{open ? 'Created' : 'Merged'}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pulls.map((pull) => {
                    return (
                      <TableRow key={pull.number}>
                        <TableCell padding="none">
                          <Link
                            to={projectArchitectureChangeRequestUrl(
                              namespace,
                              projectId,
                              pull.number
                            )}
                          >
                            {pull.number}
                          </Link>
                        </TableCell>
                        <TableCell>{pull.title}</TableCell>
                        <TableCell>
                          <ReactTimeago
                            date={pull.created_at.toDate()}
                          ></ReactTimeago>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            ) : (
              <Typography>There are no change requests at this time</Typography>
            )}
          </CardContent>
        </Card>
      ) : null}
    </Container>
  );
};

const ProjectArchitecture = ({ namespace, project: projectName, tabValue }) => {
  const [configured, setConfigured] = useState(false);
  const [description, setDescription] = useState(null);
  const [assetId, setAssetId] = useState(null);
  const theme = useTheme();

  const [account] = useAccount(namespace);

  const [project] = useProject();

  useEffect(() => {
    console.log(project);
    if (project && project.architecture.state === 'configured') {
      setConfigured(true);
    }
  }, [project]);

  useEffect(() => {
    let disposer = () => {};

    if (account.id) {
      disposer = firestore
        .collection(collections.ACCOUNTS)
        .doc(account.id)
        .collection(collections.PROJECTS)
        .doc(projectName)
        .collection(collections.ASSETS)
        .where('type', '==', 'architecture')
        .onSnapshot((snap) => {
          if (snap.docs.length > 0) {
            console.log(snap.docs[0].data());
            setDescription(snap.docs[0].data().content);
            setAssetId(snap.docs[0].id);
          }
        });
    }

    return disposer;
  }, [account.id, configured, projectName]);

  return (
    <Fragment>
      <PageHeader
        title="Architecture"
        tabs={['Description', 'Change requests', 'History']}
        value={tabValue}
      />
      {tabValue === 0 && (
        <Container>
          {configured ? (
            <Card
              style={{
                marginTop: theme.spacing(2),
              }}
            >
              {description ? (
                <CardContent>
                  {description.info ? (
                    <Grid
                      container
                      style={{
                        marginBottom: theme.spacing(1),
                      }}
                    >
                      <div style={{ flexGrow: 1 }} />
                      <TagIcon fontSize="small" />
                      <Typography>v{description.info.version}</Typography>
                    </Grid>
                  ) : null}
                  {description.elements.length > 0 ? (
                    <ArchitectureViewer
                      description={description}
                      interactive
                      namespace={namespace}
                      project={projectName}
                    />
                  ) : null}
                </CardContent>
              ) : null}
            </Card>
          ) : (
            <Card
              style={{
                width: 500,
              }}
            >
              <CardContent>
                <Typography>Architecture repository not configured.</Typography>
              </CardContent>
              <CardActions>
                <Button
                  component={Link}
                  to={projectArchitectureSetupUrl(namespace, projectName)}
                  color="primary"
                >
                  Configure
                </Button>
              </CardActions>
            </Card>
          )}
        </Container>
      )}
      {tabValue === 1 && (
        <ChangeRequests
          accountId={account.id}
          projectId={projectName}
          assetId={assetId}
          open
        ></ChangeRequests>
      )}
      {tabValue === 2 && (
        <ChangeRequests
          accountId={account.id}
          projectId={projectName}
          assetId={assetId}
        ></ChangeRequests>
      )}
    </Fragment>
  );
};

export default (props) => (
  <Page title="architecture" {...props}>
    <ProjectArchitecture {...props} />
  </Page>
);
