import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/performance';

import { result as config } from './config.json';

if (firebase.apps.length === 0) {
  const authDomain =
    process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || config.sdkConfig.authDomain;

  console.log(authDomain);

  firebase.initializeApp({
    ...config.sdkConfig,
    authDomain,
  });
}

const auth = firebase.auth();
const analytics = firebase.analytics();
const firestore = firebase.firestore();
const functions = process.env.REACT_APP_FIREBASE_REGION
  ? firebase.app().functions(process.env.REACT_APP_FIREBASE_REGION)
  : firebase.functions();
const perf = firebase.performance();

firestore.settings({});

export { auth, analytics, firebase, firestore, functions, perf };
