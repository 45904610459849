import React, { useEffect, useState } from 'react';
import Page from '../../components/pages/Project';
import PageHeader from '../../components/PageHeader';
import {
  Container,
  Card,
  CardContent,
  Typography,
  CardHeader,
  Grid,
  CardActions,
  Button,
  Divider,
} from '@material-ui/core';
import remark from 'remark';
import remark2react from 'remark-react';
import emoji from 'remark-emoji';
import { firestore } from '../../stores/firebaseInit';
import { collections } from '../../utils/db';
import { useAccount } from '../../stores/accountProvider';
import Status from '../../components/Status';
import ReactTimeago from 'react-timeago';
import { Link } from '@reach/router';
import { projectSoftwareItemUrl } from '../../utils/urls';
import { callables } from '../../utils/callables';
import { useSnackbar } from '../../stores/SnackbarProvider';

const TechnicalReport = ({ namespace, project, recordId }) => {
  const [decision, setDecision] = useState(null);
  const [account] = useAccount(namespace);
  const snackbar = useSnackbar();

  useEffect(() => {
    return account && account.id
      ? firestore
          .collection(collections.ACCOUNTS)
          .doc(account.id)
          .collection(collections.PROJECTS)
          .doc(project)
          .collection(collections.DECISIONS)
          .doc(recordId)
          .onSnapshot((snap) => {
            console.log(snap.data());
            setDecision(snap.data());
          })
      : () => {};
  }, [account, project, recordId]);

  return (
    <Page
      namespace={namespace}
      project={project}
      title={`Decision ${recordId}`}
    >
      <PageHeader title={`Decision record: ${recordId}`} tabs={['Overview']} />
      <Container>
        {decision ? (
          <Card>
            <CardHeader
              title={decision.title || 'Decision title'}
              subheader={
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  justify="flex-start"
                >
                  <Grid item>
                    <Typography variant="caption">Created:</Typography>
                  </Grid>
                  <Grid item>
                    <ReactTimeago
                      date={decision.created_at.toDate()}
                      component={Typography}
                      variant="caption"
                    />
                    .
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">Software item:</Typography>
                  </Grid>
                  <Grid item>
                    <Link
                      to={projectSoftwareItemUrl(
                        namespace,
                        project,
                        decision.assetId
                      )}
                    >
                      <Typography variant="caption">
                        {decision.assetId}
                      </Typography>
                    </Link>
                    .
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">Status:</Typography>
                  </Grid>
                  <Grid item>
                    <Status value={decision.status} />
                  </Grid>
                </Grid>
              }
            />
            <Divider />
            <CardContent
              style={{
                backgroundColor: 'whitesmoke',
              }}
            >
              {
                remark()
                  .use(emoji)
                  .use(remark2react, {
                    // sanitize: false,
                    toHast: {
                      commonmark: true,
                    },
                    remarkReactComponents: {
                      h3: ({ children }) => (
                        <Typography variant="h6">{children}</Typography>
                      ),
                      p: ({ children }) => {
                        return (
                          <Typography variant="body1">{children}</Typography>
                        );
                      },
                    },
                  })
                  .processSync(decision.content).contents
              }
            </CardContent>
            <Divider></Divider>
            {decision.status !== 'deprecated' && (
              <CardActions>
                <Button disabled>Supersede</Button>
                <Button
                  color="secondary"
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure you want to deprecate this decision?'
                      )
                    ) {
                      callables('decisions-deprecate')({
                        accountId: account.id,
                        projectId: project,
                        decisionId: recordId,
                      })
                        .then(() => {
                          snackbar.showMessage('Decision was deprecated');
                        })
                        .catch(() => {
                          snackbar.showMessage('Error');
                        });
                    }
                  }}
                >
                  Deprecate
                </Button>
              </CardActions>
            )}
          </Card>
        ) : (
          'loading'
        )}
      </Container>
    </Page>
  );
};

export default TechnicalReport;
