import React, { useEffect } from 'react';
import Page from '../../components/pages/Project';
import PageHeader from '../../components/PageHeader';
import { useState } from 'react';
import { useAccount } from '../../stores/accountProvider';
import { firestore } from '../../stores/firebaseInit';
import { collections } from '../../utils/db';
import {
  Container,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import remark from 'remark';
import remark2react from 'remark-react';
import emoji from 'remark-emoji';
import { useParams, Link } from '@reach/router';
import ReactTimeago from 'react-timeago';
import Status from '../../components/Status';
import { projectTechnicalFileUrl } from '../../utils/urls';

const DecisionsCard = () => {
  const [decisions, setDecisions] = useState([]);

  const { namespace, project, itemId } = useParams();

  const [account] = useAccount(namespace);

  useEffect(() => {
    account &&
      account.id &&
      firestore
        .collection(collections.ACCOUNTS)
        .doc(account.id)
        .collection(collections.PROJECTS)
        .doc(project)
        .collection(collections.DECISIONS)
        .where('assetId', '==', itemId)
        .get()
        .then((snap) => {
          setDecisions(snap.docs.map((doc) => doc.data()));
        });
  }, [account, project, itemId]);

  return (
    <Card>
      <CardHeader title="Active change decisions" />
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding="none">Id</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {decisions.map((decision) => {
              console.log(decision);

              return (
                <TableRow key={decision.decisionId}>
                  <TableCell padding="none">
                    <Link
                      to={projectTechnicalFileUrl(
                        namespace,
                        project,
                        decision.decisionId
                      )}
                    >
                      {decision.decisionId}
                    </Link>
                  </TableCell>
                  <TableCell>{decision.title}</TableCell>
                  <TableCell>
                    <ReactTimeago date={decision.created_at.toDate()} />
                  </TableCell>
                  <TableCell>
                    <Status value={decision.status} />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

const SoftwareItem = ({ namespace, project, itemId, tabValue }) => {
  const [item, setItem] = useState(null);

  const [account] = useAccount(namespace);

  useEffect(() => {
    account &&
      account.id &&
      firestore
        .collection(collections.ACCOUNTS)
        .doc(account.id)
        .collection(collections.PROJECTS)
        .doc(project)
        .collection(collections.ASSETS)
        .doc(itemId)
        .get()
        .then((doc) => setItem(doc.data().content));
  }, [account, itemId, project]);

  return (
    <Page namespace={namespace} project={project} title={`Software item`}>
      <PageHeader
        title={item ? item.name : itemId}
        tabs={['Overview', 'Decisions']}
        value={tabValue}
      />
      {item ? (
        <Container>
          {tabValue === 0 && (
            <Card>
              <CardHeader title="Description" />
              <CardContent>
                {
                  remark()
                    .use(emoji)
                    .use(remark2react, {
                      sanitize: false,
                      toHast: {
                        commonmark: true,
                      },
                      remarkReactComponents: {
                        h3: ({ children }) => (
                          <Typography variant="h6">{children}</Typography>
                        ),
                        p: ({ children }) => {
                          return (
                            <Typography variant="body1">{children}</Typography>
                          );
                        },
                      },
                    })
                    .processSync(item.description).contents
                }
              </CardContent>
            </Card>
          )}
          {tabValue === 1 && <DecisionsCard />}
        </Container>
      ) : (
        'loading'
      )}
    </Page>
  );
};

export default SoftwareItem;
