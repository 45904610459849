import React, { useEffect, useReducer, Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from '@reach/router';

import {
  Container,
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Typography,
  CardHeader,
} from '@material-ui/core';
import { SelectRepoStep } from './SelectRepoStep';
import ViewPullRequestStep from './ViewPullRequestStep';
import Page from '../../../components/pages/Project';
import { projectArchitectureUrl } from '../../../utils/urls';
import ResultSuccessStep from './ResultSuccessStep';
import PageHeader from '../../../components/PageHeader';
import { useProject } from '../../../stores/projectProvider';
import { useAccount } from '../../../stores/accountProvider';
import { callables } from '../../../utils/callables';

const initialState = {
  activeStep: -1,
  loading: true,
  installation: null,
};

const reducer = (state, action) => {
  const result = { ...state };

  switch (action.type) {
    case 'projectData':
      result.loading = false;

      switch (action.payload.architecture.state) {
        case 'none':
          result.activeStep = 1;
          break;
        default:
          throw new Error('invalid configuration step');
      }

      return result;
    // case 'installationData':
    //   result.installation = action.payload;
    //   result.loading = false;

    //   if (result.installation.hasOwnProperty('architecture')) {
    //     if (result.installation.architecture.hasOwnProperty('configuration')) {
    //       switch (result.installation.architecture.configuration.state) {
    //         case 'cancelled':
    //           result.activeStep = 2;
    //           result.error = 2;
    //           break;
    //         case 'started':
    //         case 'pending':
    //           result.activeStep = 1;
    //           break;
    //         case 'completed':
    //           result.activeStep = 2;
    //           break;
    //         default:
    //           throw new Error('invalid configuration state');
    //       }
    //     }
    //   } else {
    //     result.activeStep = 0;
    //   }

    //   return result;
    case 'restart':
      result.activeStep = 0;
      delete result.error;

      return result;
    case 'loading_start':
      result.loading = true;

      return result;
    case 'loading_end':
      result.loading = false;

      return result;
    default:
      throw new Error('Unknown action');
  }
};

const getSteps = () => {
  return [
    'Select repository location',
    'Review pull request on GitHub',
    'Result',
  ];
};

const ArchitectureSetup = ({ namespace }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [activeStep, setActiveStep] = useState(-1);

  const [account] = useAccount(namespace);
  const [project] = useProject();

  useEffect(() => {
    if (project) {
      console.log(project.architecture.state);
      switch (project.architecture.state) {
        case 'none':
          setActiveStep(0);
          break;
        case 'pull_request':
          setActiveStep(1);
          break;
        case 'configured':
          setActiveStep(2);
          break;
        default:
          throw new Error('invalid architecture configuration state');
      }
    }
  }, [project]);

  const steps = getSteps();

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <SelectRepoStep
            account={account}
            next={async (repo) => {
              dispatch({ type: 'loading_start' });

              await callables('architecture-startOnboarding')({
                accountId: project.accountId,
                projectName: project.name,
                repoId: repo.id,
              }).finally(() => {
                dispatch({ type: 'loading_end' });
              });
            }}
          />
        );
      case 1:
        return step === activeStep && project ? (
          <ViewPullRequestStep
            href={
              project.architecture.pull_request &&
              project.architecture.pull_request.href
            }
          ></ViewPullRequestStep>
        ) : null;
      case 2:
        return step === activeStep ? (
          <ResultSuccessStep
            next={async () => {
              navigate(projectArchitectureUrl(namespace, project.name));
            }}
            hasSpec
          ></ResultSuccessStep>
        ) : null;
      // if (!(state.installation && state.installation.architecture))
      //   return null;

      // if (
      //   state.installation.architecture.configuration.state === 'cancelled'
      // ) {
      //   return (
      //     <ResultCancelledStep
      //       cancel={async () => {
      //         dispatch({ type: 'loading_start' });

      //         await functions
      //           .httpsCallable('httpsOnCallArchitectureSetupCancel')({
      //             installation_id,
      //           })
      //           .finally(() => {
      //             dispatch({ type: 'loading_end' });
      //           });

      //         navigate(teamSoftwareUrl(installation_id));
      //       }}
      //       restart={async () => {
      //         dispatch({ type: 'loading_start' });

      //         await functions
      //           .httpsCallable('httpsOnCallArchitectureSetupCancel')({
      //             installation_id,
      //           })
      //           .finally(() => {
      //             dispatch({ type: 'loading_end' });
      //           });

      //         dispatch({ type: 'restart' });
      //       }}
      //     ></ResultCancelledStep>
      //   );
      // }

      // if (
      //   state.installation.architecture.configuration.state === 'completed'
      // ) {
      //   console.log(state.installation.architecture);
      //   return (
      //     <ResultSuccessStep
      //       next={async () => {
      //         navigate(teamSoftwareUrl(installation_id));
      //       }}
      //       hasSpec={state.installation.architecture.hasOwnProperty('spec')}
      //     ></ResultSuccessStep>
      //   );
      // }

      // return null;
      default:
        return (
          <React.Fragment>
            <Typography>Unknown step</Typography>
          </React.Fragment>
        );
    }
  };

  console.log(state);

  return (
    <Fragment>
      <Helmet>
        <title>CompliancePal: Architecture location setup</title>
      </Helmet>
      <PageHeader title="Architecture" tabs={['Configuration']} />
      <Container>
        <Card>
          <CardHeader title="Repository configuration"></CardHeader>
          <CardContent>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel error={state.error === index}>{label}</StepLabel>
                  <StepContent>{getStepContent(index)}</StepContent>
                </Step>
              ))}
            </Stepper>
          </CardContent>
        </Card>
      </Container>
    </Fragment>
  );
};

export default (props) => (
  <Page {...props}>
    <ArchitectureSetup {...props} />
  </Page>
);
