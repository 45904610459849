import React, { useEffect } from 'react';
import Page from '../../components/pages/Account';
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Grid,
  CardActions,
  Typography,
  Button,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import PageHeader from '../../components/PageHeader';
import { useState } from 'react';
import { firestore } from '../../stores/firebaseInit';
import { collections } from '../../utils/db';
import { useProfile } from '../../stores/profileProvider';
import { namespaceProjectUrl } from '../../utils/urls';
import { Link } from '@reach/router';

const ProjectCard = ({ namespace, project }) => {
  console.log(project);

  return (
    <Card>
      <CardHeader title={project.title} subheader={project.name}></CardHeader>
      <CardContent>
        {project.description.split('\n').map((paragraph, index) => (
          <Typography key={index} gutterBottom>
            {paragraph}
          </Typography>
        ))}
      </CardContent>
      <CardActions>
        <Button
          to={namespaceProjectUrl(namespace, project.name)}
          component={Link}
          color="primary"
        >
          Open
        </Button>
      </CardActions>
    </Card>
  );
};

const Projects = ({ namespace }) => {
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const [profile] = useProfile();

  useEffect(() => {
    const timeout = setTimeout(() => setLoading(false), 2000);

    const team = profile.teams.find((team) => team.namespace === namespace);

    if (team.id) {
      firestore
        .collection(collections.ACCOUNTS)
        .doc(team.id)
        .collection(collections.PROJECTS)
        .get()
        .then((snap) => {
          setLoading(false);
          setProjects(snap.docs.map((doc) => doc.data()));
        });
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [namespace, profile]);

  return (
    <Page namespace={namespace} title="Projects">
      <PageHeader title="Projects" tabs={['Active']} />
      <Container>
        <Grid container spacing={2}>
          {loading ? (
            <Grid item md={6}>
              <Card>
                <CardHeader
                  title={<Skeleton width={200} height={32} />}
                  subheader={<Skeleton width={100} height={24} />}
                ></CardHeader>
                <CardContent></CardContent>
              </Card>
            </Grid>
          ) : (
            projects.map((project, id) => (
              <Grid item key={id} md={6}>
                <ProjectCard
                  namespace={namespace}
                  project={project}
                ></ProjectCard>
              </Grid>
            ))
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default Projects;
