import React from 'react';
import Page from '../../components/pages/Account';
import PageHeader from '../../components/PageHeader';
import {
  Container,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Divider,
  makeStyles,
  useTheme,
  CardActions,
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import { useProfile } from '../../stores/profileProvider';
import Icon from '@mdi/react';
import {
  mdiCloseCircleOutline,
  mdiGithubCircle,
  mdiCheckCircleOutline,
} from '@mdi/js';
import CompliancePalIcon from '../../components/icons/CompliancePal';
import { auth, firestore } from '../../stores/firebaseInit';
import { useEffect } from 'react';
import { useState } from 'react';
import { collections } from '../../utils/db';
import { useAccount } from '../../stores/accountProvider';

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(8),
    width: theme.spacing(8),
  },
  cards: {
    marginBottom: theme.spacing(2),
  },
  dash: {
    width: theme.spacing(2),
    height: 0,
    borderTop: '1px solid lightgrey',
  },
  headers: {
    backgroundColor: 'whitesmoke',
  },
  icon: {
    height: theme.spacing(3),
    width: theme.spacing(3),
    marginLeft: -2,
    marginRight: -2,
  },
}));

const tabs = ['General', 'Git Integrations'];

export const NamespaceSettings = ({ namespace, tabValue }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const [profile] = useProfile();
  const [account] = useAccount();
  const [integration, setIntegration] = useState({});

  useEffect(() => {
    let disposer;

    // @ts-ignore
    if (account && account.integrations && account.integrations.github) {
      disposer = firestore
        .collection(collections.INTEGRATIONS)
        // @ts-ignore
        .doc(account.integrations.github)
        .onSnapshot((snap) => {
          setIntegration(snap.data());
        });
    }

    return () => disposer && disposer();
  }, [account]);

  const team = profile.teams.find((team) => team.namespace === namespace);

  const github_application =
    process.env.REACT_APP_GITHUB_APP || 'compliancepal-development';
  const target =
    auth.currentUser &&
    auth.currentUser.providerData.find(
      (provider) => provider.providerId === 'github.com'
    ).uid;

  return (
    <Page namespace={namespace} title="Settings">
      <PageHeader title="Settings" tabs={tabs} value={tabValue} />
      {tabValue === 0 && (
        <Container maxWidth="md">
          <Card className={classes.cards}>
            <CardHeader
              title="Your username"
              subheader="This is your URL namespace within CompliancePal."
            />
            <CardContent>
              <div>
                {process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}/u/{namespace}
              </div>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" disabled>
                Update
              </Button>
            </CardActions>
          </Card>
          <Card className={classes.cards}>
            <CardHeader
              title="Your name"
              subheader="Please enter your full name, or a display name you are comfortable with."
            />
            <CardContent>
              <div>name {team.name}</div>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" disabled>
                Update
              </Button>
            </CardActions>
          </Card>
          <Card className={classes.cards}>
            <CardHeader
              title="Your email"
              subheader="Please enter the email address you want to use to log in with CompliancePal."
            />
            <CardContent>
              <div>email {profile.email}</div>
            </CardContent>
            <CardActions>
              <Button size="small" color="primary" disabled>
                Update
              </Button>
            </CardActions>
          </Card>
          <Card className={classes.cards}>
            <CardHeader title="Your avatar" subheader="This is your avatar." />
            <CardContent>
              <Avatar src={team.avatar} className={classes.avatar} />
            </CardContent>
          </Card>
          <Divider />
          <Card className={classes.cards}>
            <CardHeader title="Delete your account" />
            <CardActions>
              <Button size="small" color="secondary" disabled>
                Delete
              </Button>
            </CardActions>
          </Card>
        </Container>
      )}
      {tabValue === 1 && (
        <Container maxWidth="md">
          <Card>
            <CardHeader
              title="GitHub Integration"
              subheader="You have not yet installed the Git Integration on a GitHub account."
            />
            <CardContent>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid lightgray',
                    padding: theme.spacing(1),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  <CompliancePalIcon size={0.9} />
                  <Typography
                    style={{
                      marginLeft: theme.spacing(1),
                    }}
                  >
                    {namespace}
                  </Typography>
                </Grid>
                <Grid item className={classes.dash}></Grid>
                <Grid item className={classes.icon}>
                  {integration.user ? (
                    <Icon path={mdiCheckCircleOutline} size={1} color="green" />
                  ) : (
                    <Icon path={mdiCloseCircleOutline} size={1} color="gray" />
                  )}
                </Grid>
                <Grid item className={classes.dash}></Grid>
                <Grid
                  item
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    border: '1px solid lightgray',
                    padding: theme.spacing(1),
                    borderRadius: theme.spacing(1),
                  }}
                >
                  <Icon
                    path={mdiGithubCircle}
                    size={1}
                    style={{
                      marginRight: theme.spacing(1),
                    }}
                  />
                  <Typography>
                    {integration.user ? integration.user : 'Not connected'}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              {integration.user ? null : (
                <Button
                  color="primary"
                  size="small"
                  href={`https://github.com/apps/${github_application}/installations/new/permissions?target_id=${target}&state=${profile.account}`}
                  target="_blank"
                >
                  Connect
                </Button>
              )}
            </CardActions>
          </Card>
        </Container>
      )}
    </Page>
  );
};

export default NamespaceSettings;
