import React from 'react';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useTheme,
  Typography,
} from '@material-ui/core';
import { Link } from '@reach/router';
import TimeAgo from 'react-timeago';
import { teamReportUrl, teamSoftwareItemUrl } from '../../utils/urls';
import Status from '../../components/Status';
import Icon from '@mdi/react';
import {
  mdiLanguageJavascript,
  mdiLanguagePython,
  mdiCodeBracesBox,
} from '@mdi/js';

const scopeIcon = (scope) => {
  console.log(scope);
  switch (scope) {
    case 'javascript':
      return mdiLanguageJavascript;

    case 'python':
      return mdiLanguagePython;

    default:
      return mdiCodeBracesBox;
  }
};

export const SoftwareItemLink = ({ installationId, softwareItemId }) => {
  const theme = useTheme();
  const fragments = softwareItemId.split(':');

  const [scope, name] = fragments.length > 1 ? fragments : [null, fragments[0]];

  return (
    <Link
      to={teamSoftwareItemUrl(installationId, softwareItemId)}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Icon
        size={0.9}
        path={scopeIcon(scope)}
        color={theme.palette.text.secondary}
        style={{
          marginRight: theme.spacing(0.5),
        }}
      />
      <Typography>{name}</Typography>
    </Link>
  );
};

export const DecisionRecordsCard = ({
  adrs = [],
  installationId,
  filtered = false,
  parent = false,
}) => {
  const theme = useTheme();

  console.log(adrs);

  return (
    <Card
      style={{
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      }}
    >
      <CardContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Item</TableCell>
              {parent && <TableCell>Parent</TableCell>}
              <TableCell>Status</TableCell>
              <TableCell>Created</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adrs
              .filter((adr) =>
                filtered ? adr.data.status !== 'superseded' : true
              )
              .sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1))
              .map((adr) => {
                return (
                  <TableRow key={adr.id}>
                    <TableCell>
                      <Link to={teamReportUrl(installationId, adr.id)}>
                        {adr.id}
                      </Link>
                    </TableCell>
                    <TableCell>{adr.data.title}</TableCell>
                    <TableCell>
                      <SoftwareItemLink
                        installationId={installationId}
                        softwareItemId={adr.data.item}
                      />
                    </TableCell>
                    {parent && (
                      <TableCell>
                        <Link
                          to={teamSoftwareItemUrl(
                            installationId,
                            adr.data.parent
                          )}
                        >
                          {adr.data.parent}
                        </Link>
                      </TableCell>
                    )}
                    <TableCell>
                      <Status value={adr.data.status}></Status>
                    </TableCell>
                    <TableCell>
                      <TimeAgo
                        date={
                          typeof adr.data.created_at === 'string'
                            ? adr.data.created_at
                            : adr.data.created_at.toDate()
                        }
                      ></TimeAgo>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
