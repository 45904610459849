import React, { Fragment, useEffect } from 'react';
import ProjectPage from '../../components/pages/Project';
import PageHeader from '../../components/PageHeader';
import { useProject } from '../../stores/projectProvider';
import { firestore } from 'firebase';
import { collections } from '../../utils/db';
import {
  Container,
  Card,
  CardContent,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  CardHeader,
  useTheme,
} from '@material-ui/core';
import { useState } from 'react';

const ArchitectureChangeRequest = ({ namespace, pullId, tabValue }) => {
  const theme = useTheme();
  const [project] = useProject();

  const [pull, setPull] = useState(null);

  useEffect(() => {
    firestore()
      .collection(collections.ACCOUNTS)
      .doc(project.accountId)
      .collection(collections.PROJECTS)
      .doc(project.name)
      .collection(collections.ASSETS)
      .doc(project.assets.architecture)
      .collection(collections.PULLS)
      .doc(pullId)
      .onSnapshot((snap) => {
        setPull(snap.data());
        console.log(snap.data());
      });
  }, [project, pullId]);

  return (
    <Fragment>
      <PageHeader
        title={` Architecture - Change request #${pullId}`}
        tabs={['Overview', 'Change decisions']}
        value={tabValue}
      />
      {tabValue === 0 && (
        <Container>
          <Card>
            <CardHeader
              title="Changes summary"
              subheader="The  following software items have been added, modified or removed"
            ></CardHeader>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Item</TableCell>
                    <TableCell>Parent</TableCell>
                    <TableCell>Change</TableCell>
                    <TableCell>Decision</TableCell>
                  </TableRow>
                </TableHead>
                {pull && (
                  <TableBody>
                    {Object.values(pull.changes).map((change) => {
                      // const element = pull.updates[pull.sha].elements.find(
                      //   (element) => element.id === change.id
                      // );

                      return (
                        <TableRow key={change.id}>
                          <TableCell>{change.id}</TableCell>
                          <TableCell>{change.parent}</TableCell>
                          <TableCell>{change.type}</TableCell>
                          <TableCell color="primary">
                            {change.decision ? 'yes' : 'missing'}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </Table>
            </CardContent>
          </Card>
        </Container>
      )}
      {tabValue === 1 && (
        <Container>
          {pull
            ? Object.values(pull.changes).map((change) => {
                console.log(change.decision);
                return (
                  <Card
                    key={change.id}
                    style={{
                      marginBottom: theme.spacing(2),
                    }}
                  >
                    <CardHeader
                      title={change.id}
                      subheader={`Software item has been ${change.type}`}
                    ></CardHeader>
                    <CardContent>
                      {change.decision
                        ? change.decision.content
                        : 'missing change decison'}
                    </CardContent>
                  </Card>
                );
              })
            : 'loading'}
        </Container>
      )}
    </Fragment>
  );
};

export default (props) => (
  <ProjectPage title="architecture" {...props}>
    <ArchitectureChangeRequest {...props} />
  </ProjectPage>
);
